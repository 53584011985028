
@tailwind base;
@tailwind components;
@tailwind utilities;


.gradient-text {
    background: linear-gradient(90deg, #163EA5, #21D7D9);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .gradient-text-two {
    background: linear-gradient(90deg, #163EA5, #00FFF2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .gradient-text-three {
    background: linear-gradient(90deg, #163EA5, #21D7D9);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .footer-gradient {
    background-image: linear-gradient(138deg, rgba(2, 0, 36, 1) 0%, rgba(20, 15, 81, 1) 68%, rgba(22, 58, 157, 1) 100%)
  }


  body {
    font-family: 'Montserrat', sans-serif;
  }
  

  
